// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/common/glanum-banner/glanum-banner.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/common/glanum-banner/glanum-banner.tsx");
  import.meta.hot.lastModified = "1709038524751.7402";
}
// REMIX HMR END

const GlanumBanner = ({
  imageUrl,
  imageAlt,
  vertical,
  bgColor
}) => {
  return <div className={`relative flex-[30%] ${bgColor ? bgColor : "bg-glanum-beige"}`}>
      <img className={`absolute bottom-0 w-full ${vertical ? "glanum_sm:h-[80%] md:h-[auto]" : "h-[100%]"} object-contain`} src={imageUrl} alt={imageAlt} />
    </div>;
};
_c = GlanumBanner;
export default GlanumBanner;
var _c;
$RefreshReg$(_c, "GlanumBanner");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;